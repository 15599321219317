import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const logoStyle = {
  width: '140px',
  height: 'auto',
};

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              <img
                src={
                  'logo.png'
                }
                style={logoStyle}
                alt="logo of my order portal"
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'flex' },
            mb: 5,
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Product
          </Typography>
          <Link color="text.secondary" href="#testimonials">
            Testimonials
          </Link>
          <Link color="text.secondary" href={'orderportalppt.pdf'}>
            More Info
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link color="text.secondary" href="https://myorderportal.com/frankspizzaoakridge/terms">
            Terms
          </Link>
          <Link color="text.secondary" href="https://myorderportal.com/frankspizzaoakridge/privacy">
            Privacy
          </Link>
          <Link color="text.secondary" href="mailto:enterprisewebsolutions@outlook.com">
            Contact
          </Link>
        </Box>
      </Box>
    </Container>
  );
}