import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/system';

const media = {
    width: "80%",
    height: "600px",
    margin: "auto",
  };

export default function Demovideo() {
  const theme = useTheme();

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <CardMedia
                component='video'
                src={"Tommy_v4.webm"}
                style={media}
                autoPlay 
                controls 
            />
    </Box>
  );
}