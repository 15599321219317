import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(2.5rem, 8vw, 3rem)',
            }}
          >
            Intuitive online ordering system designed for you.
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            variant='subtitle1'
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Maximize your profits and minimize your workload with our online ordering system - 
            your business's secret ingredient for efficiency!
          </Typography>
          <Box textAlign='center' >
            <Button style={{maxWidth: '100px', maxHeight: '40px', minWidth: '100px', minHeight: '40px', marginRight: '1.5rem'}} variant='contained'><a style={{color: 'white', textDecoration: 'none'}} href="tel:+19739193738">Call Now</a></Button>
            <Button style={{maxWidth: '100px', maxHeight: '40px', minWidth: '100px', minHeight: '40px'}} variant='contained'><a style={{color: 'white', textDecoration: 'none'}} href={'orderportalppt.pdf'}>More Info</a></Button>
          </Box>
          
        </Stack>
        
        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                : 'url("/static/images/templates/templates-images/hero-dark.png")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}/> */}
      </Container>
    </Box>
  );
}