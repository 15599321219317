import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    name: 'Lester Morales',
    occupation: 'Pezzo Pizza Uno Owner',
    testimonial:
      "As a restaurant owner, integrating this online ordering system was a game-changer for my business. Not only did it streamline our operations, but it also opened up a whole new avenue for reaching customers. Now, our loyal patrons can conveniently order their favorite meals from the comfort of their homes, boosting our sales and customer satisfaction. Highly recommend!",
  },
  {
    name: 'Sergio Barahona',
    occupation: 'Somerset Pizza Owner',
    testimonial:
      "I run a busy pizzeria, and I can't imagine managing it without this online ordering system. It's incredibly user-friendly, both for us as the staff and for our customers. With features like quick payment processing and seamless order management, we've been able to serve more customers efficiently, even during peak hours. Plus, the responsive design ensures our website looks great on any device. ",
  },
  {
    name: 'Gabriel Morales',
    occupation: 'Franks Oak Ridge Owner',
    testimonial:
      "I've been using this online ordering system for almost a year now, and I'm thrilled with the results. It's helped me expand my customer base beyond what I thought possible. The ability to manage orders in real-time has made running my business so much easier. Plus, the funds are deposited straight into my account, providing peace of mind. This system has truly transformed the way I do business!",
  }
];

const whiteLogos = [
  'https://myorderportal.com/pictures/pezzopizzauno.png',
  'https://myorderportal.com/pictures/somersetpizza.png',
  'https://myorderportal.com/pictures/frankspizzaoakridge.png',
];

const darkLogos = [
  'https://myorderportal.com/pictures/pezzopizzauno.png',
  'https://myorderportal.com/pictures/somersetpizza.png',
  'https://myorderportal.com/pictures/frankspizzaoakridge.png',
];

const logoStyle = {
  width: '80px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Hear It From Other Owners
        </Typography>
        <Typography variant="body1" color="text.secondary">
          See what the businesses we serve love about our system. Discover how we excel in
          efficiency, durability, and satisfaction. Join us for quality, innovation,
          and reliable support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}